<template>
  <div>
    <div class="mb-6">
      <form target="_empty" method="post" :action="spec.url">
        <input type="hidden" name="authenticity_token" :value="spec.token" />

        <button class="google_button" type="submit">
          <span class="icon" />{{ spec.text }}
        </button>
      </form>
    </div>

    <div class="d-flex divider">
      <hr class="my-auto flex-grow-1" />
      <div class="px-4">OR</div>
      <hr class="my-auto flex-grow-1" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    spec: { type: Object, required: true }
  },
  data() {
    return {
      data: {}
    };
  }
};
</script>

<style lang="scss" scoped>
// The styling should follow Google's branding guidelines (https://developers.google.com/identity/branding-guidelines)
@import "../../../../../app/assets/stylesheets/_colors.scss";

.google_button {
  display: inline-block;
  //background: white;
  color: $primary-color-dark;
  width: 100%;
  border-radius: 5px;
  border: thin solid $primary-color-dark;
  white-space: nowrap;
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 4px;
  padding-bottom: 4px;

  font-family: serif;
  font-weight: normal;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  padding-right: 10px;
}

.google_button:hover {
  cursor: pointer;
}

.icon {
  background: url("../../../../assets/images/g-logo.png") transparent 5px no-repeat;
  background-size: 50% 50%;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}

.theme--light.v-application .d-flex.divider {
  color: $medium-grey-color;
}
</style>
